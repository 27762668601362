import React, { Component } from "react";
import Scanner from "./scanner";
import { Link } from "react-router-dom";
class MainMenuComponent extends Component {
  render() {
    return (
      <>
        <Link to={{ pathname: "/" }}>
          <button className="btn btn-sm btn-success mx-2">
            <i className="fa fa-4x fa-home"></i>
          </button>
        </Link>
        <Scanner />
        <Link to={{ pathname: "/settings" }}>
          <button className="btn btn-sm btn-danger mx-2">
            <i className="fa fa-4x fa-cog"></i>
          </button>
        </Link>
      </>
    );
  }
}

export default MainMenuComponent;
