import React, { Component } from "react";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
class Scanner extends Component {
  scanEvent() {
    Swal.fire({
      icon: "question",
      title: "Ready to scan your first site?",
      confirmButtonText: "Yup !",
      showCancelButton: true,
      cancelButtonText: "Nah",
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.href = "/scanner";
      }
    });
  }

  render() {
    return (
      <Link to={{ pathname: "/scanner" }}>
        <button className="btn btn-sm btn-primary mx-2">
          <i className="fa fa-4x fa-qrcode"></i>
        </button>
      </Link>
    );
  }
}

export default Scanner;
