import React, { Component } from "react";
import MainMenuComponent from "./mainmenu";
class MainComponent extends Component {
  constructor() {
    super();

    this.listbuttonStyle = {
      backgroundColor: "#333333",
    };
  }

  render() {
    return (
      <div className="container-fluid fixed-bottom">
        <div className="row text-center" style={this.listbuttonStyle}>
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 my-4">
            <MainMenuComponent />
          </div>
        </div>
      </div>
    );
  }
}

export default MainComponent;
