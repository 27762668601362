import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Html5QrcodeScannerPlugin from "./components/qr";
import { HashRouter, Route, Routes } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./extras/font-awesome-4.7.0/css/font-awesome.min.css";
import "../node_modules/pouchdb/dist/pouchdb";
import "./register.js";
import "@sweetalert2/theme-dark/dark.css";
import MainComponent from "./components/listViewcomponent";
import SettingsComponent from "./components/settings";
import MainQr from "./components/mainqrcomponent";

const renderReactDom = () => {
  const root = ReactDOM.createRoot(document.getElementById("root"));
  root.render(
    <HashRouter>
      <Routes>
        <Route
          exact
          path="/"
          element={[
            <MainComponent key={"maincomponent"} />,
            <MainQr key={"mainqr"} />,
          ]}
        />
        <Route
          exact
          path="/scanner"
          element={[
            <MainComponent key={"maincomponent"} />,
            <Html5QrcodeScannerPlugin key={"scanner"} />,
          ]}
        />
        <Route
          exact
          path="/settings"
          element={[
            <MainComponent key={"maincomponent"} />,
            <SettingsComponent key={"settings"} />,
          ]}
        />
      </Routes>
    </HashRouter>
  );
};

if (window.cordova) {
  document.addEventListener(
    "deviceready",
    () => {
      renderReactDom();
    },
    false
  );
} else {
  renderReactDom();
}

reportWebVitals();
