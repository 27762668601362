import React, { Component } from "react";
import QrReader from "modern-react-qr-reader";
import Swal from "sweetalert2";
import PouchDB from "pouchdb/dist/pouchdb";
class Html5QrcodeScannerPlugin extends Component {
  constructor(props) {
    super(props);
    this.options = {
      facingMode: "environment",
      style: {
        color: "#ffffff",
      },
      dbname: "mainbase",
      messages: {
        scanAgain: "Scan another one !",
        addingCode: "Do you want to add this code?",
        storingError: "You already have this code in your database !",
      },
    };
    this.state = {
      delay: 200,
      result: "Target your camera towards a QR code",
      resultData: null,
      database: new PouchDB(this.options.dbname),
      dbData: null,
    };

    this.handleScan = this.handleScan.bind(this);
    this.handleError = this.handleError.bind(this);
  }
  handleScan(data) {
    if (data) {
      this.setState(
        {
          resultData: data,
        },
        () => {
          Swal.fire({
            title: this.options.messages.addingCode,
            text: this.state.resultData,
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            className: "myClass",
          }).then((result) => {
            if (result.isConfirmed) {
              this.state.database
                .put({
                  _id: this.state.resultData,
                  title: String(this.state.resultData),
                })
                .then((success) => {
                  Swal.fire({
                    title: `${this.state.resultData} was successfully stored !`,
                    icon: "success",
                    className: "myClass",
                  });
                })
                .catch((err) => {
                  Swal.fire({
                    title: `Error: ${this.options.messages.storingError}`,
                    icon: "error",
                    confirmButtonText: "Ok",
                    className: "myClass",
                  });
                });
            } else {
              Swal.fire({
                title: this.options.messages.scanAgain,
                className: "myClass",
              });
            }
          });
        }
      );
    }
  }
  handleError(err) {
    console.error(err);
  }
  render() {
    return this.state.resultData !== null ? (
      <div className="container-fluid">
        <div className="row text-center">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mx-auto">
            <QrReader
              delay={this.state.delay}
              onError={this.handleError}
              onScan={this.handleScan}
              facingMode={"environment"}
              className="myclass d-block mx-auto"
              style={{ height: 300 + `px`, width: 300 + `px` }}
            />
          </div>
        </div>
      </div>
    ) : (
      <div className="container-fluid">
        <div className="row text-center">
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 mx-auto">
            <QrReader
              delay={this.state.delay}
              onError={this.handleError}
              onScan={this.handleScan}
              facingMode={"environment"}
              className="myclass d-block mx-auto"
              style={{ height: 300 + `px`, width: 300 + `px` }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Html5QrcodeScannerPlugin;
