import React, { Component } from "react";
import PouchDB from "pouchdb/dist/pouchdb";
import Swal from "sweetalert2";
import QrCreator from "qr-creator";

class SettingsComponent extends Component {
  constructor(props) {
    super(props);
    this.options = {
      text: "Settings",
      dbname: "mainbase",
      style: {
        overflow: "hidden",
      },
      messages: {
        exportedMessage: "Exported",
        noData: "No records yet",
        error: "Some error occured !",
        deleted: "The record was deleted !",
        importantDeletionMessage: "Do you really want to delete the record ?",
      },
      searchFieldText: "Search...",
      fieldStyle: {
        width: 85 + `%`,
      },
    };
    this.state = {
      database: new PouchDB(this.options.dbname),
      dbData: null,
      recordsWereDeleted: false,
      inputFieldValue: null,
    };

    this.exportAsQr = this.exportAsQr.bind(this);
    this.deleteRecord = this.deleteRecord.bind(this);
    this.docRows = this.docRows.bind(this);
    this.changeState = this.changeState.bind(this);
  }

  componentDidMount() {
    this.docRows();
  }

  searchDatabase(passedString) {
    if (passedString === "") {
      this.docRows();
    } else {
      let searchArray = [];
      this.state.dbData.forEach((element) => {
        if (
          String(element.doc._id)
            .toLowerCase()
            .includes(String(passedString).toLocaleLowerCase())
        ) {
          searchArray.push(element);
        }
      });
      this.changeState(searchArray);
    }
  }

  docRows() {
    let base = this.state.database;
    base
      .allDocs({
        include_docs: true,
        attachments: true,
      })
      .then((data, error) => {
        if (data) {
          if (data["rows"].length > 0) {
            this.changeState(data["rows"]);
          }
        } else {
          console.log(error);
        }
      });
  }

  changeState(passedData) {
    this.setState({ dbData: passedData });
  }

  deleteRecord(passed_id) {
    let changeDataState = (passedData) => {
      this.changeState(passedData);
    };
    Swal.fire({
      title: this.options.messages.importantDeletionMessage,
      icon: "warning",
      confirmButtonText: "Yes !",
      cancelButtonText: "No !",
      showCancelButton: true,
    }).then((res) => {
      if (res.isConfirmed) {
        let base = this.state.database;
        let errorMessage = this.options.messages.error;
        let successMessage = this.options.messages.deleted;
        changeDataState(null);
        base
          .get(passed_id)
          .then(function (doc) {
            return base.remove(doc);
          })
          .then(function (result) {
            Swal.fire({
              text: successMessage,
              icon: "success",
              confirmButtonText: "Ok",
            }).then((res) => {
              if (res.isConfirmed) {
                base
                  .allDocs({
                    include_docs: true,
                    attachments: true,
                  })
                  .then((data, error) => {
                    if (data) {
                      if (data["rows"].length > 0) {
                        changeDataState(data["rows"]);
                      }
                    } else {
                      console.log(error);
                    }
                  });
              } else {
                base
                  .allDocs({
                    include_docs: true,
                    attachments: true,
                  })
                  .then((data, error) => {
                    if (data) {
                      if (data["rows"].length > 0) {
                        this.changeState(data["rows"]);
                      }
                    } else {
                      console.log(error);
                    }
                  });
              }
            });
          })
          .catch(function (err) {
            Swal.fire({
              text: errorMessage,
              icon: "error",
            });
          });
      }
    });
  }

  exportAsQr(passedUrl) {
    let data = passedUrl;
    Swal.fire({
      html: `<div id='qrcode'/>`,
      didOpen: function () {
        let qrdiv = document.querySelector("#qrcode");
        QrCreator.render(
          {
            text: data,
            radius: 0.0, // 0.0 to 0.5
            ecLevel: "L", // L, M, Q, H
            fill: "#000000", // foreground color
            background: "#ffffff", // color or null for transparent
            size: 200, // in pixels
          },
          qrdiv
        );
      },
    });
  }

  render() {
    return this.state.dbData !== null ? (
      <div className="min-vh-100" style={this.options.style}>
        <input
          className="form form-control-lg my-4"
          placeholder={this.options.searchFieldText}
          onKeyUp={(event) => {
            let inputValue = String(event.currentTarget.value).toLowerCase();
            this.setState({ inputFieldValue: inputValue }, () => {
              this.searchDatabase(this.state.inputFieldValue);
            });
          }}
          style={this.options.fieldStyle}
        ></input>
        <div className="table-responsive">
          <table className="table table-dark table-striped">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Record</th>
                <th scope="col">Delete</th>
                <th scope="col">Export</th>
              </tr>
            </thead>
            <tbody>
              {this.state.dbData.map((doc, ind) => {
                return (
                  <tr key={ind}>
                    <td>{ind}</td>
                    <td>{doc.doc.title}</td>

                    <td>
                      <button
                        data-id={doc.doc._id}
                        className="btn btn-md btn-danger"
                        onClick={(event) => {
                          const target =
                            event.currentTarget.getAttribute("data-id");
                          this.deleteRecord(target);
                        }}
                      >
                        Delete
                      </button>
                    </td>
                    <td>
                      <button
                        data-url={doc.doc.title}
                        className="btn btn-md btn-warning"
                        onClick={(event) => {
                          const target =
                            event.currentTarget.getAttribute("data-url");
                          this.exportAsQr(target);
                        }}
                      >
                        Export
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    ) : (
      <div className="min-vh-100" style={this.options.style}>
        <input
          className="form form-control-lg my-4"
          placeholder={this.options.searchFieldText}
          onKeyUp={(event) => {
            let inputValue = String(event.currentTarget.value).toLowerCase();
            this.setState({ inputFieldValue: inputValue }, () => {
              this.searchDatabase(this.state.inputFieldValue);
            });
          }}
          style={this.options.fieldStyle}
        ></input>
        <div className="table-responsive">
          <table className="table table-dark table-striped">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Record</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>{this.options.messages.noData}</td>
                <td>
                  <button className="btn btn-md btn-danger disabled">
                    Delete
                  </button>
                </td>
                <td>
                  <button className="btn btn-md btn-warning disabled">
                    Export
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default SettingsComponent;
