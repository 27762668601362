import React, { Component } from "react";

class MainQr extends Component {
  constructor(props) {
    super(props);
    this.options = {
      title: "Scan a QR to start using the app !",
      style: {
        color: "#ffffff",
      },
      imgStyle: {
        maxWidth: 100 + `%`,
        display: `block`,
        marginLeft: `auto`,
        marginRight: `auto`,
      },
    };
  }

  render() {
    return (
      <div className="vh-100">
        <h2 style={this.options.style}>{this.options.title}</h2>
        <img
          style={this.options.imgStyle}
          alt="homeqr"
          src="./images/qrcode_www.artefaktas.eu.png"
        />
      </div>
    );
  }
}

export default MainQr;
